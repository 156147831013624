import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"

import YC_2018 from "../../../static/images/ministries/youth-corps/2018.png"
import IntensivesWork from "../../../static/images/ministries/youth-corps/intensives_work.png"
import YC_2020 from "../../../static/images/ministries/youth-corps/2020.jpeg"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/ministries.css"


function YC () {
	
	const subheaderData = {
		main:"Summer Youth Corps",
		sub:""
	}

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content eri-colors youth-corps">
				<div>
					<Subheader data={subheaderData} />
				</div>
				<div>
					<div className="youth-corps-info">
						<p className="yc-info-blurb">
							Each summer the Cathedral hires several young adults (14+) to do the work needed at the Cathedral, surrounding buildings, and their ~55 acre campus.  We call this group our Summer Youth Corps, and their tasks vary widely based on what is needed that year. This might include everything from hauling firewood, finishing decks and siding,  cleaning carpets, building fences, maintaining trails and much more!  The schedule might be changed to accommodate specific projects or Church schedules but is normally Tuesday, Wednesday, and Thursday from 8:30 a.m. to 3:00 p.m. with a half hour lunch.  The Youth Corps runs for 8 weeks in June and July, with a break for July 4th week.
						</p>
						<img className="yc-2018" src={YC_2018} />


						<p className="yc-info-blurb middle-blurb">
							Applications are available online, and each applicant will have a personal interview.  You can be part of a long tradition that takes pride in the upkeep of St. John’s campus while making money and gaining work experience. If interested, apply today!
						</p>
						<img className="intensives-work" src={YC_2020} />
						<div className='youth-corps-apply'>
						<h3>The summer application period is May 1st - May 27th</h3>
							<Link className="apply-btn" to="/youth-corps/apply" target="_blank">
								Application
							</Link> 
						</div>
					</div>
				</div>
			</main>
			<Footer />		
		</div>
		)
}

export default YC 